import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent('VerticalMenu', async () => {
      const componentModule = await import(
        './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidth.skin' /* webpackChunkName: "VerticalMenu" */
      );
      return {
        component: componentModule.default,
      };
    });
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidth.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuSeparatedButtonFixedWidthSkin',
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuTextWithSeparatorsSkin/VerticalMenuTextWithSeparators.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuTextWithSeparatorsSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuTextWithSeparatorsSkin',
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuTextSkin/VerticalMenuText.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuTextSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuTextSkin',
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuSolidColorSkin/VerticalMenuSolidColor.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSolidColorSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuSolidColorSkin',
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuSeparatedButtonSkin/VerticalMenuSeparatedButton.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuSeparatedButtonSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuSeparatedButtonSkin',
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidth.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuArrowSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuArrowSkin', // legacy skin - should fallback to VerticalMenuSeparatedButtonFixedWidthSkin
    );
    hostAPI.registerComponent(
      'VerticalMenu',
      async () => {
        const componentModule = await import(
          './viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidth.skin' /* webpackChunkName: "VerticalMenu_VerticalMenuRibbonsSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'VerticalMenuRibbonsSkin', // legacy skin - should fallback to VerticalMenuSeparatedButtonFixedWidthSkin
    );
  },
};

export default entry;
