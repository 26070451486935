import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent('Section', async () => {
      const componentModule = await import(
        './viewer/Section' /* webpackChunkName: "Section" */
      );
      return {
        component: componentModule.default,
      };
    });
    hostAPI.registerComponent('HeaderSection', async () => {
      const componentModule = await import(
        './viewer/HeaderSection' /* webpackChunkName: "HeaderSection" */
      );
      return {
        component: componentModule.default,
      };
    });
    hostAPI.registerComponent('FooterSection', async () => {
      const componentModule = await import(
        './viewer/FooterSection' /* webpackChunkName: "FooterSection" */
      );
      return {
        component: componentModule.default,
      };
    });
    hostAPI.registerComponent('RefComponent', async () => {
      const componentModule = await import(
        './viewer/RefComponent' /* webpackChunkName: "RefComponent" */
      );
      return {
        component: componentModule.default,
      };
    });
    hostAPI.registerComponent('MembersAreaSection', async () => {
      const componentModule = await import(
        './viewer/MembersAreaSection' /* webpackChunkName: "MembersAreaSection" */
      );
      return {
        component: componentModule.default,
      };
    });
  },
};

export default entry;
