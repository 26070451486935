import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'Repeater',
      async () => {
        const componentModule = await import(
          './viewer/ResponsiveRepeater' /* webpackChunkName: "Repeater_Responsive" */
        );
        return {
          component: componentModule.default,
        };
      },
      'Responsive',
    );
  },
};

export default entry;
