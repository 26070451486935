import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'MenuContainer',
      async () => {
        const componentModule = await import(
          './viewer/MenuContainer' /* webpackChunkName: "MenuContainer_Classic" */
        );
        return {
          component: componentModule.default,
        };
      },
      'Classic',
    );

    hostAPI.registerComponent('MenuContainer', async () => {
      const componentModule = await import(
        './viewer/MenuContainer' /* webpackChunkName: "MenuContainer" */
      );
      return {
        component: componentModule.default,
      };
    });

    hostAPI.registerComponent(
      'MenuContainer',
      async () => {
        const componentModule = await import(
          './viewer/ResponsiveMenuContainer' /* webpackChunkName: "MenuContainer_Responsive" */
        );
        return {
          component: componentModule.default,
        };
      },
      'Responsive',
    );
  },
};

export default entry;
