import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'FormContainer',
      async () => {
        const componentModule = await import(
          './viewer/FormContainerSkin/FormContainerSkin.skin' /* webpackChunkName: "FormContainer_FormContainerSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'FormContainerSkin',
    );

    hostAPI.registerComponent(
      'FormContainer',
      async () => {
        const componentModule = await import(
          './viewer/ResponsiveSkin/ResponsiveSkin.skin' /* webpackChunkName: "FormContainer_ResponsiveSkin" */
        );
        return {
          component: componentModule.default,
        };
      },
      'ResponsiveSkin',
    );

    /** Backward compatiability - can be removed once uiType in TB is merged */
    hostAPI.registerComponent('FormContainer', async () => {
      const componentModule = await import(
        './viewer/FormContainerSkin/FormContainerSkin.skin' /* webpackChunkName: "FormContainerSkin" */
      );
      return {
        component: componentModule.default,
      };
    });
  },
};

export default entry;
